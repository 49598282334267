.open-positions-container {
  width: 100%;
  overflow: hidden;
  background: #f0f1f2;
  position: relative;
}

.allow-text {
  color: red;
  margin-bottom: 15px;
  font-size: 14px;
  width: 40%;
}

.open-form {
  width: 70%;
  padding-top: 0;
  margin: 0 auto;
  margin-top: 60px;
  background: white;
  padding: 40px;
  padding-top: 20px;
  margin-bottom: 100px;
  padding-bottom: 0;
  box-shadow: -10px 0 10px -5px rgba(0, 0, 0, 0.099),
    10px 0 10px -5px rgba(0, 0, 0, 0.064);
  /* Responsive Styles */
}
.open-form h4 {
  font-size: 19px;
  font-weight: 700;
}
.open-form .text-p {
  padding-top: 0;
  font-size: 14px;
  margin-bottom: 40px;
}
.open-form .label {
  margin-bottom: 20px;
}
.open-form .label label {
  font-size: 13px;
  font-weight: 600;
}
.open-form .label label span {
  font-size: 18px;
  color: red;
}
.open-form .label select {
  border-radius: 3px;
  width: 35%;
  font-size: 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.695);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="12" height="12"><path fill="currentColor" d="M207.029 381.476L12.686 187.317c-16.959-16.989-16.959-44.52 0-61.507l22.627-22.628c16.968-16.968 44.488-16.968 61.507 0L224 272.042l127.18-127.18c16.968-16.968 44.488-16.968 61.507 0l22.627 22.628c16.968 16.968 16.968 44.488 0 61.507L240.971 381.476c-17.048 17.048-44.559 17.048-61.507 0z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 15px center;
}
.open-form .label input {
  border-radius: 3px;
  width: 35%;
  font-size: 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.695);
}
.open-form .label .big-text {
  font-size: 24px;
  font-weight: 600;
}
.open-form .submit {
  padding-top: 60px;
  border-top: 1px solid black;
  padding: 15px 20px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 15;
}
.open-form .submit button {
  border: none;
  background: #0063dd;
  color: white;
  font-size: 14px;
  padding: 15px 20px;
  border-radius: 15px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .open-form {
    width: 90%;
    margin-top: 100px;
    padding: 30px;
  }

  .allow-text {
    margin-bottom: 15px;
    font-size: 11px;
    width: 100%;
  }

  .open-form h4 {
    font-size: 16px;
  }

  .open-form .text-p {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .open-form .label {
    margin-bottom: 15px;
  }

  .open-form .label label {
    font-size: 12px;
  }

  .open-form .label select,
  .open-form .label input {
    width: 100%;
    font-size: 14px;
  }

  .open-form .label .big-text {
    font-size: 20px;
  }

  .open-form .submit {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  .open-form .submit button {
    padding: 10px 15px;
    font-size: 14px;
  }
}

/* Mobile Landscape Responsive Styles */
@media (max-width: 1024px) and (min-width: 769px) {
  .open-form {
    width: 80%;
    padding: 35px;
  }

  .open-form .label select,
  .open-form .label input {
    width: 45%;
  }
}
