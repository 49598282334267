/* FileUpload.css */
.dropzone-container {
  border: 2px dashed #898989;
  border-radius: 4px;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: border 0.3s ease;
  width: 50%;
}

.dropzone-container:hover {
  background-color: #e9ecef;
}

.dropzone-container.error {
  border-color: red;
}

.dropzone-container p {
  margin: 0;
  color: #6c757d;
}

.dropzone-container p:first-of-type {
  font-weight: 500;
}

.dropzone-container p:last-of-type {
  color: black;
  font-weight: 500;
  margin-top: 10px;
  font-size: 16px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .dropzone-container {
    width: 80%;
    padding: 30px 15px;
  }

  .dropzone-container p:last-of-type {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .dropzone-container {
    width: 90%;
    padding: 20px 10px;
  }

  .dropzone-container p:last-of-type {
    font-size: 12px;
  }
}
